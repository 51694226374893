/* General styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    height: 100vh;
}

.App {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Navigation styles */
nav {
    margin-bottom: 20px;
    padding: 10px;
    background: #007bff;
    border-radius: 8px;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav li {
    margin-right: 20px;
}

nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

nav a:hover {
    color: #e0e0e0;
}

/* Centered Form Styles */
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 500px;
}

form div {
    margin-bottom: 15px;
    width: 100%;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;
}

/* Input and Dropdown Styles */
input,
select {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;
}

input:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

/* Button Styles */
button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

button:hover {
    background-color: #218838;
}

button:active {
    transform: scale(0.98);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    form {
        padding: 15px;
    }

    input,
    select {
        padding: 8px;
    }

    button {
        padding: 10px;
    }
}

/* Status Message Styles */
.status,
.loading {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.loading {
    color: #333;
    background-color: #f0f0f0;
}

.error {
    color: red;
    background-color: #ffe0e0;
    padding: 8px;
    border-radius: 4px;
    margin-top: 5px;
}

.success {
    color: green;
    background-color: #e0ffe0;
}

/* Heading Styles */
h1 {
    text-align: center;
}

/* Scrollable File List Styles */
/* Scrollable File List Styles */
.file-list {
    max-height: 300px;
    /* Adjust as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    /* Ensure it takes full width */
    margin-top: 15px;
}

/* Toggle Switch Styles */
.toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
}

input:checked+.slider {
    background-color: #28a745;
    /* Change background when checked */
}

input:checked+.slider:before {
    transform: translateX(20px);
    /* Move the knob to the right */
}

/* Adjust container styles to include padding */
.container {
    padding: 20px;
    /* Add some padding to the container */
}

/* Styles for the unauthenticated state */
.unauthenticated {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.auth-link {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.auth-link:hover {
    background-color: #0056b3;
}

/* Progress Bar Styles */
.progress {
    margin-top: 20px;
    width: 100%;
    height: 20px; /* Increase height for better visibility */
    border-radius: 10px;
    overflow: hidden;
    background-color: #e0e0e0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

progress[value]::-webkit-progress-bar {
    background-color: #e0e0e0; /* Background of the progress bar */
}

progress[value]::-webkit-progress-value {
    background-image: linear-gradient(to right, #28a745, #66ff99);
    border-radius: 10px;
    transition: width 0.4s ease; /* Smooth animation */
}
